<template>
  <div 
    class="item-wrapper">
    <div class="item-top">
      <div class="price desc">
        <span 
          v-if="item.old_price != '0.00'"
          class="discount" 
        >
          <span class="currency">
            {{ currency }}
          </span>
          {{ item.old_price }}
        </span>
        <span>
          <span class="currency">
            <b>{{ currency }}</b>
          </span> 
          <b>{{ item.price }}</b>
        </span>
        <span 
          v-if="discountPercentage > 0" 
          class="percent" 
        >
          <b>-{{ discountPercentage }}%</b>
        </span>
      </div>
      <div 
        class="preview" 
        @click="goToSliderItem"
      >
        <transition name="fade">
          <div 
            v-if="favoriteSuccessMessage && (favoriteSuccessId == item.id)" 
            class="desc green"
          >
            {{ $t(favoriteSuccessMessage) }}
          </div>
        </transition>
        <transition name="fade">
          <div 
            v-if="addToCartChosenItem == item.id" 
            class="desc green"
          >
            {{ $t('Added To Cart') }}
          </div>
        </transition>
        <img 
          v-if="categories && categories.length"
          :src="categoryImg" 
          class="img"
        >
        <img 
          v-else
          :src="imgDomain + item.img_url" 
          class="img"
        >
      </div>
      <div class="price-container">
        <div 
          v-if="categories && categories.length"
          class="desc category-desc" 
        >
          {{ item }}
        </div>
        <div 
          v-else
          class="desc" 
        >
          <b>{{ item.title }}</b>
        </div>
      </div>
    </div>
    <div class="item-bottom">
      <div 
        style="display: none;" 
        class="price desc"
      >
        <span 
          v-if="item.old_price != '0.00'"
          class="discount" 
        >
          <span class="currency">
            {{ currency }}
          </span>
          {{ item.old_price }}
        </span>
        <span>
          <span class="currency">
            <b>{{ currency }} </b>
          </span> 
          <b> {{ item.price }}</b>
        </span>
        <span 
          v-if="discountPercentage > 0" 
          class="percent" 
        >
          <b>-{{ discountPercentage }}%</b>
        </span>
      </div>
      <div class="desc tag">
        {{ item.hero }}
      </div>
      <div 
        v-if="isAuth"
        class="buttons" 
      >
        <div 
          class="button button-add-to-cart" 
          @click="$emit('addToCart',item, item.id)"
        >
          {{ $t('Add to cart') }}
        </div>
        <button 
          :class="['button button-favorite', {'is-favorite': item.is_user_favorite}]" 
          @click="toggleFavorite(item)" 
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: {
    isAuth: {
			type: Boolean,
			required: true
		},
    addToCartChosenItem: {
			type: [String, Number],
			required: true
		},
    favoriteSuccessId: {
			type: [String, Number],
			required: true
		},
    favoriteSuccessMessage: {
			type: String,
			required: true
		},
		item: {
			type: [String, Object],
			required: true
		},
		sliderIsClickble: {
			type: Boolean,
			required: true
		},
		currency: {
			type: String,
			required: true
		},
		categories: {
			type: Array,
			required: true
		},
	},
	data: function() {
		return {
			imgDomain: '',
      categoryImg: ''
		}
	},
	computed: {
		discountPercentage() {
			if (parseFloat(this.item.old_price) > 0) {
				const oldPrice = parseFloat(this.item.old_price);
				const newPrice = parseFloat(this.item.price);
				const discount = ((oldPrice - newPrice) / oldPrice) * 100;
				return discount.toFixed(0);
			} else {
				return 0;
			}
		},
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;

    if (this.categories && this.categories.length) {
      const cleanItem = this.item.replace(/\s+/g, '');
      this.categoryImg = require(`@/img/types/${cleanItem.toLowerCase()}.png`);
    }
    
	},
	methods: {
		goToSliderItem() {
			this.$emit('goToProduct', this.item);
		},
    toggleFavorite(item) {
			this.$emit('toggleFavorite', item);
		}
	},
}
</script>
