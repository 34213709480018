<template>
  <div class="modal contact-modal">
    <div 
      class="overlay" 
      @click="$parent.closeContact"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              src="./../images/close.svg"
              class="close" 
              @click="$parent.closeContact" 
            >
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-top">
                  <div class="title">
                    <b>{{ $t('Send your question') }}</b>
                  </div>
                </div>
                <div class="contact-form">
                  <label class="input-container">
                    <div class="desc">
                      {{ $t('Email:') }}
                    </div>
                    <input 
                      v-model="email"
                      type="text" 
                    >
                  </label>
                  <label class="input-container">
                    <div class="desc">
                      {{ $t('Transaction ID/HASH:') }}
                    </div>
                    <input 
                      v-model="transactionId"
                      type="text" 
                    >
                  </label>
                  <label class="input-container">
                    <div class="desc">
                      {{ $t('Message') }}
                    </div>
                    <textarea 
                      v-model="message"
                      type="text" 
                    />
                  </label>
                </div>
                <div class="cart-bottom">
                  <button 
                    :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]"
                    @click="send" 
                  >
                    {{ $t('send') }}
                  </button>
                </div>
                <transition name="slide">
                  <div 
                    v-if="$parent.error" 
                    class="error-desc desc red"
                  >
                    {{ $parent.error }}
                  </div>
                </transition>
                <transition name="slide">
                  <div 
                    v-if="successMessage" 
                    class="error-desc desc green"
                  >
                    {{ successMessage }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactModal',
  props: [],
  data: function() {
    return {
      email: '',
      transactionId: '',
      message: '',
      successMessage: ''
    }
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.email && this.transactionId && this.message
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  mounted() {
    
  },
  methods: {
    send() {
      let data = {
        "email": this.email,
        "transaction_id": this.transactionId,
        "message": this.message,
        
      }
      this.$http.post(process.env.VUE_APP_API + 'send-question', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.$emit('setError', '')
          let self = this;
          self.successMessage = 'Success'
          setTimeout(()=> {
            self.successMessage = ''
          }, 1000)
        } else {
          this.$emit('setError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setError', res.response.data.message)
      })
    }
  }
}
</script>