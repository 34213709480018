<template>
  <div class="modal product-modal">
    <div 
      class="overlay"
      @click="$parent.closeProductModal"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              class="close" 
              src="./../images/close.svg"
              @click="$parent.closeProductModal"
            >
            <div class="product-modal-top">
              <div class="preview">
                <img 
                  :src="$parent.imgDomain + $parent.productPageItem.img_url" 
                  class="img"
                >
              </div>
              <div class="text-container">
                <div class="text">
                  <div class="title">
                    {{ $parent.productPageItem.title }} 
                  </div>
                  <div class="left">
                    <div class="desc desc-quality">
                      <span>{{ $t('Hero:') }}</span>
                      <b>{{ $parent.productPageItem.hero }}</b>
                    </div>
                    <div class="desc desc-quality">
                      <span>{{ $t('Rarity:') }}</span>
                      <b>{{ $parent.productPageItem.quality }}</b>
                    </div>
                    <div class="desc desc-quality">
                      <span>{{ $t('Type:') }}</span>
                      <b>{{ $parent.productPageItem.type }}</b>
                    </div>
                  </div>
                  <div class="right">
                    <div class="right-text">
                      <div class="title small price">
                        <span class="currency">
                          {{ $parent.currency }}
                        </span>
                        {{ $parent.productPageItem.price }}
                      </div>
                      <!-- <div class="desc">
                        <span>Steam price:</span>
                        <b>€5.68</b>
                      </div> -->
                    </div>
                    <div class="buttons">
                      <button 
                        v-if="$parent.isAuth"
                        class="button"  
                        @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)"
                      >
                        {{ $t('Add to cart') }}
                      </button>
                      <button 
                        v-if="$parent.isAuth"
                        :class="['button dark button-favorite', {'is-favorite': $parent.productPageItem.is_user_favorite}]"
                        @click="$parent.toggleFavorite($parent.productPageItem)"
                      >
                        <img 
                          v-if="$parent.productPageItem.is_user_favorite"
                          class="img" 
                          src="./../images/heartFilled.svg"
                        >
                        <img 
                          v-else
                          class="img" 
                          src="./../images/heart.svg"
                        >
                        <span 
                          v-if="$parent.productPageItem.is_user_favorite"
                        >
                          {{ $t('Remove from favorites') }}
                        </span>
                        <span v-else>
                          {{ $t('Add to favorites') }}
                        </span>
                      </button>
                    </div>
                  </div>
                  <transition name="slide">
                    <div 
                      v-if="$parent.addToCartChosenItem" 
                      class="desc green"
                    >
                      {{ $t('Added') }}
                    </div>
                  </transition>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $parent.error }}
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <div class="product-modal-bottom">
              <div 
                v-if="productPageItem.similar && productPageItem.similar.length"  
                class="title title-main"
              >
                {{ $t('Avaible variants') }}
              </div>
              <transition name="fade">
                <div 
                  v-if="productPageItem.similar && productPageItem.similar.length"
                  class="list products"
                >
                  <div 
                    v-for="(item, i) in productPageItem.similar" 
                    :key="i"
                    class="item" 
                  >
                    <ProductCard 
                      :categories="[]" 
                      :item="item" 
                      :add-to-cart-chosen-item="$parent.addToCartChosenItem" 
                      :slider-is-clickble="true" 
                      :currency="currency" 
                      :favorite-success-message="$parent.favoriteSuccessId"
                      :favorite-success-id="$parent.favoriteSuccessId"
                      :is-auth="$parent.isAuth"
                      @goToProduct="goToProduct" 
                      @addToCart="addToCart"
                      @toggleFavorite="toggleFavorite"
                    />
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductCard from '../components/ProductCard.vue';
export default {
  name: 'ProductModal',
  components: {
    ProductCard
  },
  props: {
    productPageItem: {
			type: [Object, String],
			required: true
		},
    currency: {
      type: String,
      required: true
    },
  },
  data: function() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    toggleFavorite(item) {
			this.$emit('toggleFavorite', item);
		}
  }
}
</script>