<template>
  <div class="slider-container">
    <slick 
      ref="slick"  
      :options="slickOptions" 
      class="slider products"
      @swipe="handleSwipe"
    >
      <div 
        v-for="item in list" 
        :key="item.id"
        class="item" 
      >
        <ProductCard
          :categories="categories" 
          :currency="currency" 
          :is-auth="isAuth" 
          :item="item" 
          :slider-is-clickble="sliderIsClickble" 
          :favorite-success-message="favoriteSuccessMessage"
          :favorite-success-id="favoriteSuccessId"
          :add-to-cart-chosen-item="addToCartChosenItem" 
          @goToProduct="goToProduct" 
          @openSignInModal="openSignInModal"
          @openBuyModal="openBuyModal" 
          @openReadyModal="openReadyModal" 
        />
      </div>
    </slick>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick';

export default {
  name: 'SlickItem',
  components: {
    Slick,
    ProductCard
  },
  props: {
		list: {
			type: Array,
			required: true
		},
		currency: {
			type: String,
			required: true
		},
    isAuth: {
			type: Boolean,
			required: true
		},
    categories: {
			type: Array,
			required: true
		},
    favoriteSuccessId: {
			type: [String, Number],
			required: true
		},
    favoriteSuccessMessage: {
			type: String,
			required: true
		},
    addToCartChosenItem: {
      type: String,
      required: true
    },
	},
  data: function() {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        slidesToShow: 6,
        arrows: false,
        variableWidth: false,
        infinite: false,
        centerMode: false,
        slidesToScroll: 3,
        dots: false,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    global.jQuery('.slick-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
        console.log(1)
        global.jQuery(this).find('.slick-current').prev().prev().addClass('before-current');
        global.jQuery(this).find('.slick-current').prev().prev().siblings().removeClass('before-current');
      } else if (nextSlide > currentSlide || (currentSlide === (slick.$slides.length - 1) && nextSlide === 0 )) {
        global.jQuery(this).find('.slick-current').addClass('before-current');
        global.jQuery(this).find('.slick-current').siblings().removeClass('before-current');
      } else {
        global.jQuery(this).find('.slick-current').prev().prev().addClass('before-current');
        global.jQuery(this).find('.slick-current').prev().prev().siblings().removeClass('before-current');
      }
      global.jQuery(this).find('.slick-slide').first().addClass('first');
    });
  
  },
  methods: {
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$emit('openReadyModal');
		},
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
  }
}
</script>