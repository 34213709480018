<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link
          to="/" 
          class="logo"
        >
          <img 
            src="./../images/logo.svg" 
            class="img"
          >
          <div class="desc">
            <b>Aegisskins</b>
          </div>
        </router-link>
      </div>
      <div class="right">
        <ul class="nav">
          <li class="item">
            <router-link 
              to="/" 
              class="desc"
            >
              <b>Home</b>
            </router-link>
          </li>
          <li class="item">
            <router-link 
              to="/product-list" 
              class="desc"
            >
              <b>Collection</b>
            </router-link>
          </li>
          <li class="item">
            <a 
              class="desc"
              @click="scrollToSection('faq')"
            >
              <b>FAQ</b>
            </a>
          </li>
        </ul>
        <div 
          v-if="curr"
          class="select-container" 
        >
          <Select2 
            v-model="curr"
            class="select2 single currency-select" 
            :placeholder="curr"
            :options="$parent.currencyOptions"
            @select="selectCurr($event)"
          />
        </div>
        <div 
          v-if="currentLanguage && currentLanguageName"
          class="select-container language-select-container" 
        >
          <Select2 
            v-model="language"
            class="select2 single currency-select" 
            :options="languages"
            :placeholder="currentLanguageName"
            @select="selectLanguage($event)"
          />
        </div>
        <div 
          v-if="$parent.isAuth"
          class="cash" 
        >
          <img 
            src="./../images/wallet.svg" 
            class="img"
          >
          <div class="desc">
            <span class="currency">{{ $parent.currency }}</span>
            {{ balance }}
          </div>
          <button 
            class="button dark" 
            @click="$parent.openTopup"
          >
            <img 
              src="./../images/plus.svg" 
              class="img"
            >
          </button>
        </div>
        <button 
          v-if="$parent.isAuth" 
          :class="['icon icon-cart round', {'gray': !cartContents.length}]"
          @click="$parent.openCart" 
        >
          <img 
            src="./../images/cart.svg" 
            class="img"
          >
          <div 
            class="indicator"
          >
            ({{ cartContents.length }} items)
          </div>
        </button>
        <button
          v-if="$parent.isAuth"
          class="button dark link-container dropdown-open round"
          @click="$parent.openProfileModal"
        >
          <img 
            src="./../images/user.svg" 
            class="img"
          >
        </button>
        <div 
          v-else
          class="link-container" 
        >
          <button 
            class="button steam" 
            @click="$parent.submitLoginSteam"
          >
            <img 
              src="./../images/steam.svg" 
              class="img"
            >
            <span>{{ $t('Sign in with steam') }}</span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import Select2 from 'vue3-select2-component';
import {mapState, mapMutations, mapGetters, mapActions} from "vuex";
export default {
	name: 'Header',
  components: {
    Select2
  },
  props: {
    cartContents: {
      type: [Array, String],
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
    searchedProductText: {
      type: String,
      required: true
    },
    isAuth: {
      type: Boolean,
      required: true
    },
    appBalance: {
      type: [String, Number],
      required: true
    },
    
  },
  
	data: function() {
		return {
      search: '',
      curr: '',
      balance: '0.00'
		}
	},
  computed: {
    ...mapState({
      languages: ({app: {languages}}) => languages.map(({id, title: text}) => ({id, text})),
      currentLanguage: state => state.app.currentLanguage,
    }),
    ...mapGetters('app', ['currentLanguageName']),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      }
    }
  },
  watch: {
    currencyCode(newValue) {
      this.curr = newValue
    },
    searchedProductText: function(newVal) {
      this.search = newVal;
    },
    isAuth() {
			this.getBalance();
		},
    appBalance() {
      this.balance = this.appBalance;
    }
  },
  mounted() {
    this.curr = this.currencyCode
    this.getBalance();
  },
	methods: {
    getBalance() {
      if (this.$parent.isAuth) {
        this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
            this.balance = res.data.balance;
        })
        .catch(() => {
        })
      }
    },
    searchSubmit() {
      this.$emit('searchSubmit', this.search)
    },
    onEnter() {
      this.searchSubmit();
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    openSignIn() {
      this.$parent.openSignIn();
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    ...mapMutations('app' , ['setCurrentLanguage']),
    ...mapActions('app', ['changeLocale'])
	}
}
</script>
