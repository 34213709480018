<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <img 
          src="./../images/heroBg.png" 
          class="bg-img"
        >
        <div class="wrapper">
          <div class="hero-top">
            <img 
              src="./../images/hero.png" 
              class="img"
            >
            <div class="title big">
              AegisSkins
            </div>
            <div class="desc">
              <b>{{ $t('Your ultimate Dota 2 skins destination') }}</b>
            </div>
            <div class="list">
              <div class="item">
                <div class="item-wrapper">
                  <div class="desc">
                    <b>
                      {{ $t('SKINS BOUGHT') }}
                    </b>
                  </div>
                  <div class="title small">
                    2,818,734
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-wrapper">
                  <div class="desc">
                    <b>
                      {{ $t('AVERAGE PAYOUT TIME') }}
                    </b>
                  </div>
                  <div class="title small">
                    0m 59s
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-wrapper">
                  <div class="desc">
                    <b>
                      {{ $t('registered accounts') }}
                    </b>
                  </div>
                  <div class="title small">
                    881,178
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hero-bottom">
            <div class="title small">
              {{ $t('Discover a world where your favorite Dota 2 skins are just a click away') }}
              <img 
                src="./../images/hero2.png" 
                class="img"
              >
            </div>
            <div class="desc">
              {{ $t('Discover a world where your favorite Dota 2 skins are just a click away. At AegisSkins.com, we bridge the gap between you and the exclusive skins you\'ve been searching for. Dive into our simplified platform designed for seamless navigation from our homepage directly to our vast skins marketplace.') }}
            </div>
          </div>
        </div>
      </div>
      <div class="section home-products-section">
        <img 
          src="./../images/latestBg.png" 
          class="latest-bg"
        >
        <div class="wrapper list products">
          <div class="title small">
            {{ $t('Latest Dota2 skins sold on AegisSkins.com') }}
          </div>
          <SlickItem 
            v-if="$parent.latestSoldProducts.length"
            :currency="currency"
            :is-auth="$parent.isAuth"
            :categories="[]"
            :add-to-cart-chosen-item="addToCartChosenItem" 
            :list="$parent.latestSoldProducts" 
            :favorite-success-message="favoriteSuccessMessage"
            :favorite-success-id="favoriteSuccessId"
            @goToProduct="goToProduct" 
            @addToCart="addToCart" 
          />
        </div>
      </div>
      <div class="section how-it-works-section">
        <div class="wrapper">
          <div class="list">
            <div class="item">
              <img 
                src="./../images/bg2.png" 
                class="bg"
              >
              <div class="text">
                <div class="title">
                  {{ $t('Get Started Today') }}
                </div>
                <div class="desc">
                  {{ $t('Dive into our curated collection of Dota 2 skins and enjoy a shopping experience designed with you in mind. [Your Site Name] is where your next game-enhancing skin awaits. Ready to elevate your Dota 2 gameplay with some new skins?') }}
                </div>
                <button class="button">
                  {{ $t('Browse our collection now') }}
                </button>
                <img 
                  src="./../images/willow.png" 
                  class="img"
                >
              </div>
            </div>
            <div class="item">
              <img 
                src="./../images/wards.png" 
                class="img"
              >
              <div class="text">
                <div class="title">
                  {{ $t('Stay Connected') }}
                </div>
                <div class="desc">
                  {{ $t('Have questions or need assistance? Our support team is just a click away. Get in touch with us through our support page for swift and friendly assistance tailored to your needs.') }}
                </div>
                <button 
                  class="button" 
                  @click="$parent.openContact"
                >
                  {{ $t('Support') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
        id="faq"
        class="section faq-section" 
      >
        <div class="wrapper">
          <img 
            src="./../images/swag_bag.png" 
            class="img"
          >
          <div class="title small">
            {{ $t('Frequently Asked Questions') }}
          </div>
          <div class="accordion">
            <div
              v-for="(item, index) in faq"
              :key="index"
              class="accordion-item"
            >
              <div
                class="title small accordion-header"
                :class="{ active: activeIndex === index }"
                @click="toggleAccordion(index)"
              >
                {{ item.question }}
              </div>
              <div 
                v-show="activeIndex === index"
                class="desc accordion-content" 
              >
                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import SlickItem from '../components/SlickItem.vue';

export default {
  name: 'Home',
  components: {
    SlickItem
  },
  props: {
    addToCartChosenItem: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    favoriteSuccessId: {
			type: [String, Number],
			required: true
		},
    favoriteSuccessMessage: {
			type: String,
			required: true
		}
  },
  data: function() {
    return {
      imgDomain: '',
      list: [],
      activeIndex: null,
      faq: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getFaq();
  },
  methods: {
    getFaq() {
      this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
          this.faq = res.data.payload;
      })
      .catch((res) => {
      })
    },
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null; // Collapse if already open
      } else {
        this.activeIndex = index; // Expand clicked section
      }
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>