<template>
  <div class="modal topup-modal">
    <div 
      class="overlay" 
      @click="$parent.closeTopup"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              src="./../images/close.svg"
              class="close" 
              @click="$parent.closeTopup" 
            >
            <div class="form-fields">
              <div class="title title-main">
                <b>{{ $t('Top up your balance') }}</b>
              </div>
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="topup-form">
                    <div class="desc desc-main">
                      {{ $t('ADD Funds') }}
                    </div>
                    <div class="buttons">
                      <label>
                        <input 
                          v-model="amount" 
                          type="radio" 
                          value="9.99" 
                        >
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 9.99
                        </div>
                      </label>
                      <label>
                        <input 
                          v-model="amount" 
                          type="radio" 
                          value="24.99" 
                        >
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 24.99
                        </div>
                      </label>
                      <label>
                        <input 
                          v-model="amount" 
                          type="radio" 
                          value="49.99"
                        >
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 49.99
                        </div>
                      </label>
                      <label>
                        <input 
                          v-model="amount" 
                          type="radio" 
                          value="99.99" 
                        >
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 99.99
                        </div>
                      </label>
                      <label>
                        <input 
                          v-model="amount" 
                          type="radio" 
                          value="249.99" 
                        >
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 249.99
                        </div>
                      </label>
                      <label>
                        <input 
                          v-model="amount" 
                          type="radio" 
                          value="499.99" 
                        >
                        <div class="button">
                          <span class="currency">{{ $parent.currency }}</span> 499.99
                        </div>
                      </label>
                    </div>
                    <div class="desc desc-main">
                      {{ $t('OR') }}
                    </div>
                    <div class="desc desc-custom-amount">
                      {{ $t('Custom amount:') }}
                    </div>
                    <div class="input-container">
                      <input 
                        v-model="amount"
                        type="text" 
                        @input="allowOnlyNumbers" 
                      >
                      <div class="desc">
                        {{ $parent.currency }}
                      </div>
                    </div>
                    <div class="desc desc-min-amout">
                      {{ $t('Minimum amount is') }} <b> 5 {{ $parent.currency }} </b>
                    </div>
                  </div>
                </div>
                <div class="cart-bottom">
                  <div class="desc desc-main">
                    {{ $t('Payment method') }}
                  </div>
                  <div class="payment-method">
                    <img 
                      src="./../images/creditCard.svg"
                      class="img" 
                    >
                    <div class="desc">
                      <b>{{ $t('Credit card') }}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="desc">
                      {{ $t('Total') }}
                    </div>
                    <div class="desc">
                      <b>{{ $parent.currency }} {{ amount }}</b>
                    </div>
                  </div>
                  <div class="desc">
                    {{ $t('Email:') }}
                  </div>
                  <div class="input-container">
                    <input 
                      v-model="email"
                      type="email" 
                    >
                  </div>
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input 
                          v-model="terms"
                          type="checkbox" 
                          name="terms" 
                        >
                        <div class="checkbox" />
                        <span class="title">{{ $t('I agree with') }} </span>
                        <a 
                          class="title"
                          @click="$parent.goToPage('privacy')"
                        > 
                          {{ $t('privacy policy') }}
                        </a>
                        <span class="title"> {{ $t('and') }} </span>
                        <a 
                          class="title"
                          @click="$parent.goToPage('terms')"
                        > 
                          {{ $t('terms and conditions') }}
                        </a>
                      </div>
                    </label>
                  </div>
                  
                  <button 
                    :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]"
                    @click="send" 
                  >
                    {{ $t('Pay') }}
                  </button>
                  <transition name="slide">
                    <div 
                      v-if="$parent.error" 
                      class="error-desc desc red"
                    >
                      {{ $parent.error }}
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopUp',
  props: {
    profileData: {
      type: Object,
      required: true
    },
  },
  data: function() {
    return {
      amount: '9.99',
      email: '',
      terms: false
    }
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.terms && this.email
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  watch: {
    profileData() {
      this.email = this.profileData.email;
    }
  },
  mounted() {
    this.email = this.profileData.email;
  },
  methods: {
    allowOnlyNumbers(){
      this.amount = this.amount.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    send() {
      if (this.amount) {
        this.$http.get(process.env.VUE_APP_API + 'user/profile')
          .then((res) => {
            let data = {
            "name": res.data.name,
            "surname": res.data.surname,
            "phone": res.data.phone,
            "country": res.data.country,
            "city": res.data.city,
            "address": res.data.address,
            "postCode": res.data.zip,
            "steamId": res.data.steamId,
            "amount": this.amount,
            "email": this.email
          }
          this.$http.post(process.env.VUE_APP_API + 'deposit/checkout', data)
          .then((res) => {
            if (res.data.status == "OK") {
              window.location = res.data.redirect_url;
            } else {
              this.$emit('setError', res.data.message);
            }
          })
          .catch((res) => {
            this.$emit('setError', res.response.data.message)
          })
        })
        .catch(() => {
        })
      } else {
        this.$emit('setError', 'Please enter amount')
      }
    }
  }
}
</script>